import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import ExternalLink from "../components/blog/ExternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";
import BlogHeading3 from "../components/blog/BlogHeading3";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlogPost = ({location}) =>{
    return  <BlogPostWrapper> <StyledMainContent>
        <SEO
            title="The Only Pantry Staples List You Will Ever Need"
            description="Whether you're looking for basic pantry staples, healthy ones, those geared towards vegans or keto enthusiasts, this article has them all."
            location={location}
            pageType="article"
        />

        <HeroSection>
            <BlogTitle>
                The Only Pantry Staples List You Will Ever Need
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/the-only-pantry-staples-list-you-will-ever-need.jpg" alt="The Only Pantry Staples List You Will Ever Need"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@abra_kadaaabra?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Artem Kostelnyuk</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/pantry-staples?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
            </ImageWithCaption>
        </HeroSection>
        <Paragraph>
            Buckle up dear readers, it’s time for the most comprehensive list of pantry staples ever. 
        </Paragraph>
        <Paragraph>
            Basic pantry items every home should have? Vegan pantry staples? Keto pantry staples? Otherwise healthy pantry staples? We’ve got them all!
        </Paragraph>
        <Paragraph>
            Through trial and error and extensive conversations with a variety of home cooks and meal preppers, we’ve decided to compile all our newfound knowledge about pantry essentials into this extensive article.
        </Paragraph>
        <Paragraph>
            Let’s start from pantry basics everyone should have so we can move on to more advanced <InternalLink to="/pantry-stock-list/"> pantry stocking</InternalLink>. 
        </Paragraph>
        <Paragraph>
            Enjoy!
        </Paragraph>
        <BlogHeading2>
            Basic pantry staples
        </BlogHeading2>
        <Paragraph>
            Even though we talked about essential pantry items to stock up on before, this article wouldn’t be what it is if we didn’t lay the foundation with basic pantry staples every home needs.
        </Paragraph>
        <Paragraph>
            If you are building a pantry from the ground up or are maybe looking for inspiration on what you can add to complete your collection of pantry essentials, look no further than this section.
        </Paragraph>
        <Paragraph>
            (Of course, if there is anything on our lists that you and/or your household members are not a fan of, feel free to leave it out! Always tailor your pantry according to your tastes and needs.)
        </Paragraph>
        <BlogHeading3>
            Grains and starches
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Dried pasta (any shape or shapes you like)</UnorderedListItem>
            <UnorderedListItem>Plain bread crumbs</UnorderedListItem>
            <UnorderedListItem>Grain crackers</UnorderedListItem>
            <UnorderedListItem>Rice (white rice, brown rice, or any other type you enjoy)</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Produce
        </BlogHeading3>
        <Paragraph>
            The following items can (and should) be kept in the pantry, even though they are fresh produce.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>All-purpose potatoes</UnorderedListItem>
            <UnorderedListItem>Onions</UnorderedListItem>
            <UnorderedListItem>Lemons</UnorderedListItem>
            <UnorderedListItem>Garlic</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Canned goods
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Tomato paste</UnorderedListItem>
            <UnorderedListItem>Tomato sauce</UnorderedListItem>
            <UnorderedListItem>Diced tomatoes</UnorderedListItem>
            <UnorderedListItem>Canned tuna (in oil, brine, or spring water, whatever your preference may be)</UnorderedListItem>
            <UnorderedListItem>Chicken stock</UnorderedListItem>
            <UnorderedListItem>Vegetable stock</UnorderedListItem>
            <UnorderedListItem>Canned soup</UnorderedListItem>
            <UnorderedListItem>Canned beans: black beans, white beans, or chickpeas</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Spices and dried herbs
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Salt</UnorderedListItem>
            <UnorderedListItem>Black peppercorns</UnorderedListItem>
            <UnorderedListItem>Curry powder</UnorderedListItem>
            <UnorderedListItem>Bay leaves</UnorderedListItem>
            <UnorderedListItem>Dried oregano</UnorderedListItem>
            <UnorderedListItem>Garlic powder</UnorderedListItem>
            <UnorderedListItem>Ground cayenne</UnorderedListItem>
            <UnorderedListItem>Red pepper flakes</UnorderedListItem>
            <UnorderedListItem>Sweet paprika</UnorderedListItem>
            <UnorderedListItem>Ground cinnamon</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Sweeteners
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Maple syrup</UnorderedListItem>
            <UnorderedListItem>Granulated sugar</UnorderedListItem>
            <UnorderedListItem>Honey</UnorderedListItem>
        </UnorderedList>
        <BlockQuote>
            <b>PRO TIP:</b> A <InternalLink to="/#download-section">free shopping list app</InternalLink> will ensure that you never again forget your shopping list at home.
        </BlockQuote>
        <BlogHeading3>
            Oils and vinegars
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Neutral cooking oil (canola, sunflower seed, or grapeseed oil)</UnorderedListItem>
            <UnorderedListItem>Extra virgin olive oil</UnorderedListItem>
            <UnorderedListItem>White vinegar (or white wine vinegar)</UnorderedListItem>
            <UnorderedListItem>Red wine vinegar</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Nuts
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Almonds</UnorderedListItem>
            <UnorderedListItem>Roasted peanuts</UnorderedListItem>
            <UnorderedListItem>Peanut butter (both smooth and crunchy, or your personal preference)</UnorderedListItem>
            <UnorderedListItem>Walnuts</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Baking items
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Baking soda</UnorderedListItem>
            <UnorderedListItem>Baking powder</UnorderedListItem>
            <UnorderedListItem>All-purpose flour</UnorderedListItem>
            <UnorderedListItem>Cornmeal</UnorderedListItem>
            <UnorderedListItem>Baking chocolate</UnorderedListItem>
            <UnorderedListItem>Brown sugar</UnorderedListItem>
            <UnorderedListItem>Powdered sugar</UnorderedListItem>
            <UnorderedListItem>Pure vanilla extract</UnorderedListItem>
            <UnorderedListItem>Cornstarch</UnorderedListItem>
            <UnorderedListItem>Cocoa powder</UnorderedListItem>
            <UnorderedListItem>Chocolate chips</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Preserves and pickled food
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Fruit jams (apricot, strawberry, any other kind you like)</UnorderedListItem>
            <UnorderedListItem>Pickles</UnorderedListItem>
            <UnorderedListItem>Anchovies</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Condiments and sauces
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Mustard (Dijon or yellow)</UnorderedListItem>
            <UnorderedListItem>Soy sauce and/or tamari sauce</UnorderedListItem>
            <UnorderedListItem>Salsa</UnorderedListItem>
            <UnorderedListItem>Ketchup</UnorderedListItem>
            <UnorderedListItem>Hot sauce</UnorderedListItem>
            <UnorderedListItem>Basic vinaigrette</UnorderedListItem>
            <UnorderedListItem>Mayonnaise</UnorderedListItem>
        </UnorderedList>
        <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/2022-10-pantry-staples-list-bowl-of-colorful-veggies.png" alt="A bowl of colorful veggies"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@annapelzer?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Anna Pelzer</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/healthy-food?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
        </ImageWithCaption>
        <BlogHeading2>
            Healthy pantry staples
        </BlogHeading2>
        <Paragraph>
            To be fair, most - if not all - of the items from our basic staples list can be considered healthy if consumed in moderation.
        </Paragraph>
        <Paragraph>
            However, if you are looking for food items that are truly beneficial for your health, we could refine that list a bit.
        </Paragraph>
        <Paragraph>
            First, let's see what it means to have a healthy diet.
        </Paragraph>
        <Paragraph>
            <ExternalLink to="https://www.who.int/news-room/fact-sheets/detail/healthy-diet">The World Health Organization (WHO)</ExternalLink> states that a healthy diet is one rich in fruits and vegetables. It also contains the right amount of legumes, nuts and seeds, and whole grains.
        </Paragraph>
        <Paragraph>
            At the same time, WHO recommends everyone to limit their intake of fats (especially saturated fats and trans-fats), free sugars (those added by manufacturers, cooks, or consumers), and salt.
        </Paragraph>
        <Paragraph>
            With that in mind, here is a list of five groups of <InternalLink to = "/healthy-pantry-staples/">healthy pantry staples for a well-balanced diet</InternalLink>.
        </Paragraph>
        <BlogHeading3>
            Fruits and vegetables
        </BlogHeading3>
        <Paragraph>
            Unfortunately, a large number of fruits and vegetables are highly perishable (berries, for example) and cannot be kept in the pantry.
        </Paragraph>
        <Paragraph>
            Since we won't be talking about items stored in the fridge or freezer, here is a limited list of healthy fruits and veggies that can last a while in a dry, cool place in the pantry: 
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Carrots</UnorderedListItem>
            <UnorderedListItem>Sweet potatoes</UnorderedListItem>
            <UnorderedListItem>Beets</UnorderedListItem>
            <UnorderedListItem>Cabbage</UnorderedListItem>
            <UnorderedListItem>Butternut squash</UnorderedListItem>
            <UnorderedListItem>Rutabagas</UnorderedListItem>
            <UnorderedListItem>Spaghetti squash</UnorderedListItem>
            <UnorderedListItem>Apples</UnorderedListItem>
            <UnorderedListItem>Pomegranates</UnorderedListItem>
            <UnorderedListItem>Citrus fruits (lemons, oranges, tangerines, etc.)</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            <b>Why are fruits and vegetables healthy?</b>
        </Paragraph>
        <Paragraph>
            These two food groups are a crucial source of vitamins, minerals, and dietary fiber.
        </Paragraph>
        <Paragraph>
            According to the <ExternalLink to = "https://www.hsph.harvard.edu/nutritionsource/what-should-you-eat/vegetables-and-fruits/">Harvard School of Public Health</ExternalLink>, they can lower blood pressure, reduce the risk of certain cancers, heart disease, stroke, eye problems, digestive problems, and more.
        </Paragraph>
        <BlogHeading3>
            Legumes
        </BlogHeading3>
        <Paragraph>
            Beans, lentils, chickpeas, peanuts (yes, you read that right - peanuts are not nuts but legumes), soybeans, peas, and similar foods belong in a group called legumes.
        </Paragraph>
        <Paragraph>
            Dried and canned versions of these foods have an exceptionally long shelf life. So long, in fact, that we added them onto our list of <InternalLink to= "/how-to-stock-a-pantry-for-a-year/">pantry items that will last you over a year</InternalLink>.
        </Paragraph>
        <Paragraph>
            Here are the legumes you should stock your pantry with:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Canned or dried beans (black beans, white beans, kidney beans, etc.)</UnorderedListItem>
            <UnorderedListItem>Canned or dried chickpeas</UnorderedListItem>
            <UnorderedListItem>Canned or dried lentils</UnorderedListItem>
            <UnorderedListItem>Peanuts (and peanut butter, but the kind made without any added sugar)</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            <b>Why are legumes healthy?</b>
        </Paragraph>
        <Paragraph>
            Legumes <ExternalLink to= "https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/legumes/art-20044278">contain zero cholesterol</ExternalLink> , are low in fat, and high in potassium, magnesium, and folate. These three substances support the function of cells, muscles, and nerves. Folate promotes healthy red blood cell growth and formation in the body.
        </Paragraph>
        <Paragraph>
            Legumes are also high in protein, making them an excellent meat alternative for those who are on a plant-based diet.
        </Paragraph>
        <BlogHeading3>
            Nuts and seeds
        </BlogHeading3>
        <Paragraph>
            Nuts and seeds can be added to salads, smoothies, baked goods, oatmeal dishes, and more.
        </Paragraph>
        <Paragraph>
            They are high in calories, meaning they should be consumed in small amounts to maintain a healthy weight, but they’re jam-packed with nutrients that your body will thank you for.
        </Paragraph>
        <Paragraph>
            Here is a list of nuts and seeds you can keep in your pantry:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Almonds</UnorderedListItem>
            <UnorderedListItem>Walnuts</UnorderedListItem>
            <UnorderedListItem>Hazelnuts</UnorderedListItem>
            <UnorderedListItem>Macadamia nuts</UnorderedListItem>
            <UnorderedListItem>Pine nuts</UnorderedListItem>
            <UnorderedListItem>Pecans</UnorderedListItem>
            <UnorderedListItem>Cashews</UnorderedListItem>
            <UnorderedListItem>Pistachios</UnorderedListItem>
            <UnorderedListItem>Flaxseed</UnorderedListItem>
            <UnorderedListItem>Sunflower seeds</UnorderedListItem>
            <UnorderedListItem>Pumpkin seeds</UnorderedListItem>
            <UnorderedListItem>Chia seeds</UnorderedListItem>
            <UnorderedListItem>Hemp seeds</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Nut butters also belong in this category, but make sure to look for those without added sugars.
        </Paragraph>
        <Paragraph>
            <b>Why are nuts and seeds healthy?</b>
        </Paragraph>
        <Paragraph>
            Nuts and seeds <ExternalLink to= "https://www.healthline.com/nutrition/8-benefits-of-nuts">contain a lot of antioxidants</ExternalLink>, fiber, vitamins, and minerals. They have the potential to aid in weight loss, lower cholesterol and triglyceride levels, and are beneficial against type 2 diabetes and metabolic syndrome.
        </Paragraph>
        <BlogHeading3>
            Whole grains
        </BlogHeading3>
        <Paragraph>
            The main difference between whole grains (such as brown rice) and refined grains (such as white rice) is that refined grains are stripped of valuable nutrients during the refining process. Therefore, refined grains don’t provide the same health benefits as whole grains.
        </Paragraph>
        <Paragraph>
            Both of these can have a place in your diet, but an emphasis should be placed on whole grains.
        </Paragraph>
        <Paragraph>
            Consider adding the following whole grains to your pantry:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Brown rice</UnorderedListItem>
            <UnorderedListItem>Spelt</UnorderedListItem>
            <UnorderedListItem>Bulgur</UnorderedListItem>
            <UnorderedListItem>Oats</UnorderedListItem>
            <UnorderedListItem>Amaranth</UnorderedListItem>
            <UnorderedListItem>Quinoa</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            <b>Why are whole grains healthy?</b>
        </Paragraph>
        <Paragraph>
            Compared to refined grains, whole grains contain antioxidants, B vitamins, vitamin E, fiber, and a whole range of phytonutrients and minerals (such as manganese and magnesium).
        </Paragraph>
        <Paragraph>
            Consuming a diet with the right amount of whole grains can <ExternalLink to= "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5310957/">reduce the risk</ExternalLink> of type 2 diabetes, certain cancers (pancreatic, colorectal, and gastric cancer) and cardiovascular disease
        </Paragraph>
        <BlogHeading3>
            Healthy fats
        </BlogHeading3>
        <Paragraph>
            We already mentioned that the WHO advises limiting your intake of all fats, especially saturated and trans fats.
        </Paragraph>
        <Paragraph>
            How can you know which fats are healthy for you and which are not?
        </Paragraph>
        <Paragraph>
            Let's take the guesswork out of it. Here is a list of pantry foods with healthy fats:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Canned tuna</UnorderedListItem>
            <UnorderedListItem>Canned salmon</UnorderedListItem>
            <UnorderedListItem>Olive oil (extra virgin olive oil is the best option)</UnorderedListItem>
            <UnorderedListItem>Avocado oil</UnorderedListItem>
            <UnorderedListItem>Safflower oil</UnorderedListItem>
            <UnorderedListItem>Sesame oil</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            <b>What makes healthy fats healthy?</b>
        </Paragraph>
        <Paragraph>
            <ExternalLink to= "https://www.hsph.harvard.edu/nutritionsource/what-should-you-eat/fats-and-cholesterol/types-of-fat/">Healthy or 'good' fats</ExternalLink> are unsaturated fats (monounsaturated and polyunsaturated fats). They reduce inflammation in the body, cholesterol levels, and stabilize the heart rhythm, among other benefits.
        </Paragraph>
        <Paragraph>
            In contrast, 'bad' fats are saturated and trans fats. They <i>increase</i> the risk of disease, such as type 2 diabetes and coronary heart disease. They also create inflammation in the body.
        </Paragraph>
        <BlogHeading2>
            Vegan pantry staples
        </BlogHeading2>
        <Paragraph>
            The global vegan population is growing by the year. Just in the United States, the number of people who identify as vegan <ExternalLink to="https://www.reportbuyer.com/product/4959853/top-trends-in-prepared-foods-exploring-trends-in-meat-fish-and-seafood-pasta-noodles-and-rice-prepared-meals-savory-deli-food-soup-and-meat-substitutes.html">went up by 600%</ExternalLink> from 2014 to 2017.
        </Paragraph>
        <Paragraph>
            And it's no wonder - the health benefits of veganism, vegetarianism, and plant-based diets are unquestionable: 
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><ExternalLink to = "https://pubmed.ncbi.nlm.nih.gov/26853923/">Reduced risk of cancer</ExternalLink></UnorderedListItem>
            <UnorderedListItem><ExternalLink to = "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5555375/">Reduced risk of heart disease</ExternalLink></UnorderedListItem>
            <UnorderedListItem><ExternalLink to = "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6133017/">Reduced risk of diabetes</ExternalLink></UnorderedListItem>
            <UnorderedListItem>Reduced inflammation and <ExternalLink to = "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4359818/">arthritis pain</ExternalLink>, and more.</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            However, the benefits of veganism, vegetarianism, and plant-based diets extend way beyond human health.
        </Paragraph>
        <Paragraph>
            By bypassing the meat and dairy industries, these diets indirectly contribute to protecting animal welfare and reversing climate change.
        </Paragraph>
        <Paragraph>
            If you're just starting your vegan journey or would like to know more about what to include in your vegan pantry, here's a neat little list of <InternalLink to = "/vegan-pantry-staples/">vegan pantry staples</InternalLink>. 
        </Paragraph>
        <BlogHeading3>
            Baking essentials
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Baking powder</UnorderedListItem>
            <UnorderedListItem>Baking soda</UnorderedListItem>
            <UnorderedListItem>Cornstarch</UnorderedListItem>
            <UnorderedListItem>Dairy-free chocolate or chocolate chips</UnorderedListItem>
            <UnorderedListItem>Flour (all-purpose flour, spelt flour, oat flour, almond flour, brown rice flour, or any kind you prefer)</UnorderedListItem>
            <UnorderedListItem>Vanilla extract</UnorderedListItem>
            <UnorderedListItem>Unsweetened cocoa powder</UnorderedListItem>
            <UnorderedListItem>A selection of sweeteners (check out the Sweeteners section below)</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Beans and legumes
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Black beans</UnorderedListItem>
            <UnorderedListItem>Chickpeas</UnorderedListItem>
            <UnorderedListItem>Garbanzo beans</UnorderedListItem>
            <UnorderedListItem>Kidney beans</UnorderedListItem>
            <UnorderedListItem>Lentils</UnorderedListItem>
            <UnorderedListItem>Navy beans</UnorderedListItem>
            <UnorderedListItem>Peanuts</UnorderedListItem>
            <UnorderedListItem>Peas</UnorderedListItem>
            <UnorderedListItem>Pinto beans, etc.</UnorderedListItem>
            <UnorderedListItem>Soybeans</UnorderedListItem>
            <UnorderedListItem>White beans</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Canned goods
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Baked or cooked beans</UnorderedListItem>
            <UnorderedListItem>Coconut milk</UnorderedListItem>
            <UnorderedListItem>Diced tomatoes</UnorderedListItem>
            <UnorderedListItem>Jams (apricot, strawberry, mango, and more)</UnorderedListItem>
            <UnorderedListItem>Lemon or lime juice</UnorderedListItem>
            <UnorderedListItem>Olives</UnorderedListItem>
            <UnorderedListItem>Pasta sauce</UnorderedListItem>
            <UnorderedListItem>Salsa</UnorderedListItem>
            <UnorderedListItem>Sun-dried tomatoes</UnorderedListItem>
            <UnorderedListItem>Tomato paste</UnorderedListItem>
            <UnorderedListItem>Unsweetened applesauce</UnorderedListItem>
        </UnorderedList>
        <BlockQuote>
            <b>PRO TIP:</b> Add these items to a <InternalLink to="/#download-section">free shopping list app</InternalLink> for a time-saving shopping trip!
        </BlockQuote>
        <BlogHeading3>
            Grains
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Barley</UnorderedListItem>
            <UnorderedListItem>Bulgur</UnorderedListItem>
            <UnorderedListItem>Couscous</UnorderedListItem>
            <UnorderedListItem>Oats</UnorderedListItem>
            <UnorderedListItem>Quinoa</UnorderedListItem>
            <UnorderedListItem>Rice</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Nuts
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Almonds</UnorderedListItem>
            <UnorderedListItem>Brazil nuts</UnorderedListItem>
            <UnorderedListItem>Cashews</UnorderedListItem>
            <UnorderedListItem>Hazelnuts</UnorderedListItem>
            <UnorderedListItem>Macadamia nuts</UnorderedListItem>
            <UnorderedListItem>Pecans</UnorderedListItem>
            <UnorderedListItem>Pistachios</UnorderedListItem>
            <UnorderedListItem>Walnuts</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Pasta
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Black bean pasta</UnorderedListItem>
            <UnorderedListItem>Brown rice pasta</UnorderedListItem>
            <UnorderedListItem>Chickpea pasta</UnorderedListItem>
            <UnorderedListItem>Quinoa pasta</UnorderedListItem>
            <UnorderedListItem>Red lentil pasta</UnorderedListItem>
            <UnorderedListItem>Whole wheat pasta</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Seeds
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Chia seeds</UnorderedListItem>
            <UnorderedListItem>Flaxseeds</UnorderedListItem>
            <UnorderedListItem>Pumpkin seeds</UnorderedListItem>
            <UnorderedListItem>Sesame seeds</UnorderedListItem>
            <UnorderedListItem>Sunflower seeds</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Spices
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Salt (himalayan salt, smoked salt, sea salt, or any kind you like)</UnorderedListItem>
            <UnorderedListItem>Black pepper</UnorderedListItem>
            <UnorderedListItem>Cardamom</UnorderedListItem>
            <UnorderedListItem>Cayenne</UnorderedListItem>
            <UnorderedListItem>Chili powder</UnorderedListItem>
            <UnorderedListItem>Cinnamon</UnorderedListItem>
            <UnorderedListItem>Coriander</UnorderedListItem>
            <UnorderedListItem>Cumin</UnorderedListItem>
            <UnorderedListItem>Curry powder</UnorderedListItem>
            <UnorderedListItem>Dried parsley (though fresh is always better)</UnorderedListItem>
            <UnorderedListItem>Garlic powder</UnorderedListItem>
            <UnorderedListItem>Ginger</UnorderedListItem>
            <UnorderedListItem>Nutmeg</UnorderedListItem>
            <UnorderedListItem>Oregano</UnorderedListItem>
            <UnorderedListItem>Red pepper flakes</UnorderedListItem>
            <UnorderedListItem>Thyme</UnorderedListItem>
            <UnorderedListItem>Turmeric</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Sweeteners
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Brown sugar</UnorderedListItem>
            <UnorderedListItem>Coconut sugar</UnorderedListItem>
            <UnorderedListItem>Date sugar (or actual dates)</UnorderedListItem>
            <UnorderedListItem>Granulated sugar</UnorderedListItem>
            <UnorderedListItem>Maple syrup</UnorderedListItem>
            <UnorderedListItem>Monk fruit</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Bonus
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Vegetable broth base or bouillon cubes - for soups, sauces, dips, casseroles, pasta dishes, etc.</UnorderedListItem>
            <UnorderedListItem>Nutritional yeast - for seasoning popcorn, salads, pastas, casseroles, for thickening sauces and soups, and adding that cheesy flavor.</UnorderedListItem>
            <UnorderedListItem>Mushrooms - white button, portobello, shiitake, oyster, etc.</UnorderedListItem>
            <UnorderedListItem>Wraps (nori wraps, tortillas) - for burrito and sushi recipes.</UnorderedListItem>
        </UnorderedList>
        <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/2022-10-pantry-staples-list-plate-of-salmon-with-butter-garlic-and-spices.png" alt="Plate of salmon surrounded by butter, garlic, and spices"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@dbtownsend?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">David B Townsend</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/keto-diet?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
        </ImageWithCaption>
        <BlogHeading2>
            Keto diet pantry staples
        </BlogHeading2>
        <Paragraph>
            The ketogenic diet is one of the most popular diets in the last few years. It is a low carb, high fat diet that assists in fat loss and contributes to lower risk of type 2 diabetes, cancer, high blood pressure, and more.
        </Paragraph>
        <Paragraph>
            <ExternalLink to= "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5842847/">Some studies</ExternalLink> also suggest that the keto diet could have antitumor effects.
        </Paragraph>
        <Paragraph>
            For a full overview of keto health benefits, head on over to our article on how to <InternalLink to = "/keto-pantry-staples/">crush your keto diet</InternalLink>.
        </Paragraph>
        <Paragraph>
            If you're interested in filling your kitchen pantry with as many keto-friendly foods as you can, the following list is just for you.
        </Paragraph>
        <BlogHeading3>
            Meat and seafood
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Anchovies in olive oil</UnorderedListItem>
            <UnorderedListItem>Canned ham</UnorderedListItem>
            <UnorderedListItem>Canned salmon</UnorderedListItem>
            <UnorderedListItem>Canned tuna</UnorderedListItem>
            <UnorderedListItem>Sardines</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Nuts and seeds
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Almonds</UnorderedListItem>
            <UnorderedListItem>Almond butter</UnorderedListItem>
            <UnorderedListItem>Chia seeds</UnorderedListItem>
            <UnorderedListItem>Flaxseeds</UnorderedListItem>
            <UnorderedListItem>Macadamia nuts</UnorderedListItem>
            <UnorderedListItem>Macadamia nut butter</UnorderedListItem>
            <UnorderedListItem>Pecans</UnorderedListItem>
            <UnorderedListItem>Pumpkin seeds</UnorderedListItem>
            <UnorderedListItem>Sunflower seeds</UnorderedListItem>
            <UnorderedListItem>Sunflower seed butter</UnorderedListItem>
            <UnorderedListItem>Walnuts</UnorderedListItem>
        </UnorderedList>
        <BlockQuote>
            <b>PRO TIP:</b> Use a <InternalLink to="/#download-section">free shopping list app</InternalLink> to quickly and easily take this keto pantry list with you on your next grocery run.
        </BlockQuote>
        <BlogHeading3>
            Dry goods
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Almond flour</UnorderedListItem>
            <UnorderedListItem>Chia flour</UnorderedListItem>
            <UnorderedListItem>Coconut flour</UnorderedListItem>
            <UnorderedListItem>Coffee</UnorderedListItem>
            <UnorderedListItem>Dark chocolate (with over 70% of cocoa and only in small to moderate amounts)</UnorderedListItem>
            <UnorderedListItem>Psyllium husk powder</UnorderedListItem>
            <UnorderedListItem>Tea</UnorderedListItem>
            <UnorderedListItem>Unsweetened cocoa powder</UnorderedListItem>
            <UnorderedListItem>Unsweetened dry coconut</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Oils and condiments
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Avocado oil</UnorderedListItem>
            <UnorderedListItem>Apple cider vinegar</UnorderedListItem>
            <UnorderedListItem>Coconut oil</UnorderedListItem>
            <UnorderedListItem>Hot sauce</UnorderedListItem>
            <UnorderedListItem>Mayonnaise (carefully read the label to avoid mayo with unhealthy fats)</UnorderedListItem>
            <UnorderedListItem>Mustard (but not honey mustard or other sweetened variations)</UnorderedListItem>
            <UnorderedListItem>Olive oil</UnorderedListItem>
            <UnorderedListItem>Salsa</UnorderedListItem>
            <UnorderedListItem>Soy sauce</UnorderedListItem>
            <UnorderedListItem>Sugar-free ketchup</UnorderedListItem>
            <UnorderedListItem>Sugar-free salad dressings</UnorderedListItem>
            <UnorderedListItem>Tamari sauce</UnorderedListItem>
            <UnorderedListItem>Wine vinegar (red wine and white wine)</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Herbs and spices
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Basil</UnorderedListItem>
            <UnorderedListItem>Bay leaf</UnorderedListItem>
            <UnorderedListItem>Chives</UnorderedListItem>
            <UnorderedListItem>Cilantro</UnorderedListItem>
            <UnorderedListItem>Dill</UnorderedListItem>
            <UnorderedListItem>Oregano</UnorderedListItem>
            <UnorderedListItem>Parsley</UnorderedListItem>
            <UnorderedListItem>Peppermint</UnorderedListItem>
            <UnorderedListItem>Rosemary</UnorderedListItem>
            <UnorderedListItem>Sage</UnorderedListItem>
            <UnorderedListItem>Spearmint</UnorderedListItem>
            <UnorderedListItem>Cinnamon</UnorderedListItem>
            <UnorderedListItem>Coriander seed</UnorderedListItem>
            <UnorderedListItem>Caraway seed</UnorderedListItem>
            <UnorderedListItem>Curry powder</UnorderedListItem>
            <UnorderedListItem>Cayenne pepper</UnorderedListItem>
            <UnorderedListItem>Chili powder</UnorderedListItem>
            <UnorderedListItem>Ground mustard seed</UnorderedListItem>
            <UnorderedListItem>Paprika</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Final thoughts
        </BlogHeading2>
        <Paragraph>
            Whether you're looking for basic pantry staples, healthy ones, those geared towards vegans or pantry staples for keto enthusiasts, this article has it all. Let it serve as an inspiration for building the ultimate pantry for your home.
        </Paragraph>
        <Paragraph>
            Make sure to bookmark this post and refer back to it in the future.
        </Paragraph>
        <Paragraph>
            Don't forget that you can always get our free shopping list app to store these lists in. Create one list for each type of pantry, or mix and match with our fun color coding and tagging systems. <InternalLink to="/#download-section">Download it here!</InternalLink>
        </Paragraph>
    </StyledMainContent></BlogPostWrapper>
}



export default BlogPost;